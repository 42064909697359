<template>
  <v-card class="card-shadow border-radius-xl">
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    ></common-loading>
    <case-detail
      :display="case_display"
      :propEditedItem="case_edited_item"
      :propEditMode="case_edit_mode"
      :propCaseMode="propCaseMode"
      v-on:onClose="close"
      v-on:onActionDone="onActionDone"
      v-on:onReload="onReload"
    ></case-detail>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">{{ propTitle }}</h5>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!readOnly"
        :ripple="false"
        elevation="0"
        color="#fff"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-primary
          py-5
          px-4
          mx-2
        "
        small
        @click="addCase()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        新增案件
      </v-btn>
      <v-btn
        v-if="!readOnly && isAllowExportExcelCase()"
        :ripple="false"
        elevation="0"
        color="#fff"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-success
          py-5
          px-4
          mx-2
        "
        small
        :disabled="selected.length == 0"
        @click="exportExcel()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        匯出總表
      </v-btn>
    </v-card-title>
    <!-- search -->
    <v-row class="my-0 py-0" no-gutters v-if="propAllowSearch">
      <v-col sm="1" cols="12" class="pl-2" dense>
        <label class="text-md font-weight-bolder ms-1">案件編號</label>
        <v-text-field
          hide-details
          outlined
          placeholder="案件編號"
          :class="common_input_class"
          v-model="search.serial_number"
        >
        </v-text-field>
      </v-col>

      <v-col sm="1" cols="12" class="pl-2" dense>
        <label class="text-md font-weight-bolder ms-1">土地/建物</label>
        <v-select
          :items="object_type_filter"
          v-model="search.type"
          :class="common_input_class"
          outlined
          single-line
          hide-details
          @change="onSelectType(search.type)"
        >
        </v-select>
      </v-col>
      <v-col sm="2" cols="12" class="pl-2">
        <label class="text-md font-weight-bolder ms-1">案件名稱</label>
        <v-text-field
          hide-details
          outlined
          placeholder="案件名稱"
          :class="common_input_class"
          v-model="search.name"
        >
        </v-text-field>
      </v-col>
      <v-col sm="1" cols="12" class="ml-2">
        <label class="text-md font-weight-bolder ms-1">縣市</label>
        <v-autocomplete
          :items="city_info"
          v-model="search.city"
          :class="common_input_class"
          outlined
          single-line
          hide-details
          @change="onSelectCity(search.city)"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col sm="2" cols="12">
        <label class="text-md font-weight-bolder">行政區</label>
        <v-autocomplete
          :items="districts"
          v-model="search.district"
          :class="common_input_class"
          outlined
          single-line
          hide-details
          @change="selectDistrict(search.city, search.district)"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col
        cols="6"
        md="1"
        class="my-0 py-0 pl-2"
        v-if="isAllowFilterDeal() && !readOnly"
      >
        <label class="text-md font-weight-bolder pl-1">成交</label>
        <v-select
          :items="deal_status_filter"
          v-model="search.deal"
          :class="common_input_class"
          outlined
          single-line
          hide-details
        >
        </v-select>
      </v-col>
      <v-col
        cols="6"
        md="1"
        class="my-0 py-0 pl-2"
        v-if="propCaseMode != case_mode_enum.Draft"
      >
        <label class="text-md font-weight-bolder pl-1">上架</label>
        <v-select
          :items="shelf_filter_options"
          v-model="search.shelf"
          :class="common_input_class"
          outlined
          single-line
          hide-details
        >
        </v-select>
      </v-col>
      <v-col
        cols="6"
        md="1"
        class="my-0 py-0 pl-2"
        v-if="isAllowFilterShelf() && !readOnly"
      >
        <label class="text-md font-weight-bolder pl-1">審核</label>
        <v-select
          :items="review_status_enum_str"
          v-model="search.review"
          :class="common_input_class"
          outlined
          single-line
          hide-details
        >
        </v-select>
      </v-col>
      <v-col
        cols="6"
        md="1"
        class="my-0 py-0 pl-2"
        v-if="isAllowFilterCreator()"
      >
        <label class="text-md font-weight-bolder pl-1">參與人員</label>
        <v-select
          :items="sales_filter"
          v-model="search.sale_id"
          item-text="full_name"
          item-value="id"
          :class="common_input_class"
          outlined
          single-line
          hide-details
        >
        </v-select>
      </v-col>
    </v-row>
    <!-- search-common 未分區 不分土地或建物 -->
    <v-row
      class="my-0 py-0"
      no-gutters
      v-if="propAllowSearch && search.type == null"
    >
      <v-col cols="6" md="1" class="my-0 py-0 pl-2">
        <label class="text-md font-weight-bolder pl-1">總價(低)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_min"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">總價(高)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_max"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0 pl-2">
        <label class="text-md font-weight-bolder pl-1">面積（小)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_min"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">面積（大)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_max"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="my-0 py-0 pl-2">
        <label class="text-md font-weight-bolder pl-1">座向</label>
        <v-select
          :items="orientation_enum_str"
          v-model="search.orientation"
          :class="common_input_class"
          hide-details
          outlined
          single-line
          :disabled="readOnly"
        >
        </v-select>
      </v-col>
      <!-- 銷售類型 -->
      <v-col md="2" cols="5" class="py-1 pl-2">
        <label class="font-weight-bolder ms-1">銷售類型</label>
        <v-row class="pl-2">
          <v-radio-group row v-model="search.sale_or_rent">
            <v-radio
              :ripple="false"
              v-for="(item, i) in sale_or_rent_enum_str"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              dense
            ></v-radio>
          </v-radio-group>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="my-4" align-self="center">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-5
            px-4
            mx-2
          "
          small
          @click="onSearch()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Search") }}
        </v-btn>

        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default btn-outline-secondary
            py-5
            px-4
            mx-2
          "
          small
          @click="reset()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Reset") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- search-land land 土地 -->
    <v-row
      class="my-0 py-0 mx-2"
      no-gutters
      v-if="propAllowSearch && search.type == object_type_enum.Land"
    >
      <v-col sm="2" cols="12" class="px-0">
        <label class="text-md font-weight-bolder ms-1">地段小段</label>
        <v-autocomplete
          :items="regions"
          v-model="search.region"
          :class="common_input_class"
          clearable
          outlined
          single-line
          hide-details
          item-value="region_code"
          :item-text="(item) => item.region_name + '(' + item.region_code + ')'"
          :disabled="search.type == null"
        >
        </v-autocomplete>
      </v-col>
      <v-col sm="1" cols="12">
        <label class="text-md font-weight-bolder ms-1">母號</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.mno"
          clearable
          :disabled="search.type == null"
        >
        </v-text-field>
      </v-col>
      <v-col sm="1" cols="12">
        <label class="text-md font-weight-bolder ms-1">子號</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.sno"
          clearable
          :disabled="search.type == null"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0 ml-2">
        <label class="text-md font-weight-bolder pl-1">總價(低)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_min"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">總價(高)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_max"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0 ml-2">
        <label class="text-md font-weight-bolder pl-1">面積（小)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_min"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">面積（大)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_max"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      class="my-0 py-0 mx-2"
      no-gutters
      v-if="propAllowSearch && search.type == object_type_enum.Land"
    >
      <v-col
        sm="4"
        cols="12"
        class="my-1 py-0 pl-2"
        v-if="search.type == object_type_enum.Land"
      >
        <label class="text-md font-weight-bolder ms-1">土地所屬</label>
        <v-row no-gutters>
          <v-checkbox
            v-for="(item, index) in land_ownership_enum_str"
            :key="index"
            class="px-1 my-0"
            v-model="search.land_ownerships"
            :label="item.text"
            :value="item.value"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col
        sm="3"
        cols="12"
        class="my-1 py-0 pl-2"
        v-if="search.type == object_type_enum.Land"
      >
        <label class="text-md font-weight-bolder ms-1">土地類別</label>
        <v-row no-gutters>
          <v-checkbox
            v-for="(item, index) in land_category_enum_str"
            :key="index"
            class="px-1 my-0"
            v-model="search.land_category"
            :label="item.text"
            :value="item.value"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="3" cols="12" class="pl-2">
        <label class="text-md font-weight-bolder ms-1">臨路狀況</label>
        <v-row class="ml-2">
          <v-radio-group row v-model="search.road_conditions">
            <v-radio
              v-for="(item, i) in road_condition_enum_str"
              :key="i + item"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" class="my-4 text-end">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-5
            px-4
            mx-2
          "
          small
          @click="onSearch()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Search") }}
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default btn-outline-secondary
            py-5
            px-4
            mx-2
          "
          small
          @click="reset()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Reset") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- search-building 建物 -->
    <v-row
      class="my-0 py-0 mx-2"
      no-gutters
      v-if="propAllowSearch && search.type == object_type_enum.Building"
    >
      <!-- 建物 -->
      <v-col sm="3" cols="12" class="px-0">
        <label class="text-md font-weight-bolder ms-1">道路</label>
        <v-autocomplete
          :items="roads"
          v-model="search.road"
          :class="common_input_class"
          outlined
          single-line
          hide-details
          item-value="road_code"
          item-text="road_name"
        >
        </v-autocomplete>
      </v-col>
      <v-col sm="4" cols="12" class="pl-2">
        <label class="text-md font-weight-bolder ms-1">地址</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.address"
        >
        </v-text-field>
      </v-col>
      <v-col sm="4" cols="12" class="pl-2">
        <label class="text-md font-weight-bolder ms-1">建物</label>
        <v-text-field
          hide-details
          outlined
          placeholder="建物名稱"
          :class="common_input_class"
          v-model="search.object_name"
        >
        </v-text-field>
      </v-col>
      <v-col sm="1" cols="12"></v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">總價(低)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_min"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">總價(高)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.total_max"
          type="number"
          suffix="萬"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="ml-2 my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">面積（小)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_min"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="1" class="my-0 py-0">
        <label class="text-md font-weight-bolder pl-1">面積（大)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.area_max"
          type="number"
          suffix="坪"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="1"
        class="my-0 py-0 ml-2"
        v-if="search.type == object_type_enum.Building"
      >
        <label class="text-md font-weight-bolder ms-1">房間（小)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.room_min"
          type="number"
          suffix="房"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="1"
        class="my-0 py-0"
        v-if="search.type == object_type_enum.Building"
      >
        <label class="text-md font-weight-bolder ms-1">房間（大)</label>
        <v-text-field
          hide-details
          outlined
          :class="common_input_class"
          v-model="search.room_max"
          type="number"
          suffix="房"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="1"
        class="my-0 py-0 ml-2"
        v-if="search.type == object_type_enum.Building"
      >
        <label class="text-md font-weight-bolder ms-1">車位有無</label>
        <v-radio-group row v-model="search.has_parking" class="mt-0">
          <v-radio class="mx-0" label="無" value="false"></v-radio>
          <v-radio class="mx-0" label="有" value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      class="my-0 py-0 mx-2"
      no-gutters
      v-if="propAllowSearch && search.type == object_type_enum.Building"
    >
      <v-col
        cols="auto"
        class="my-0 py-0 px-0"
        v-if="search.type == object_type_enum.Building"
      >
        <label class="text-md font-weight-bolder">建物形式</label>
        <v-row no-gutters>
          <v-checkbox
            dense
            v-for="(item, index) in building_style_enum_str"
            :key="index"
            v-model="search.styles"
            :label="item.text"
            :value="item.value"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="my-0 py-0 pl-2"
        v-if="search.type == object_type_enum.Building"
      >
        <label class="text-md font-weight-bolder">建物用途</label>
        <v-row no-gutters>
          <v-checkbox
            dense
            v-for="(item, index) in building_purpose_enum_str"
            :key="index"
            v-model="search.purposes"
            :label="item.text"
            :value="item.value"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" class="my-4 text-end">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-5
            px-4
            mx-2
          "
          small
          @click="onSearch()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Search") }}
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default btn-outline-secondary
            py-5
            px-4
            mx-2
          "
          small
          @click="reset()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Reset") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row v-if="propCaseMode == case_mode_enum.Manage" class="mt-2">
      <v-spacer></v-spacer>
      <v-col cols="4" class="my-0 py-0">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-5
            px-4
            mx-2
          "
          small
          @click="onSearch()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Search") }}xx
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default btn-outline-secondary
            py-5
            px-4
            mx-2
          "
          small
          @click="reset()"
        >
          <v-icon class="me-2" size="12">fa fa-search</v-icon>
          {{ $t("Reset") }}
        </v-btn>
      </v-col>
    </v-row> -->
    <v-card-text class="px-0 py-0">
      <v-data-table
        :headers="headers"
        :options.sync="options"
        :items="case_items"
        :server-items-length="case_items_count"
        :loading="data_loading"
        hide-default-footer
        disable-pagination
        class="row-height-50"
        v-model="selected"
        :show-select="isAllowExportExcelCase()"
      >
        <template v-slot:loading>
          {{ $t("Loading...") }}
        </template>
        <template v-slot:top>
          <slot name="header"> </slot>
          <v-row>
            <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#cb0c9f"
                v-model="page"
                :length="getPageCount()"
                circle
                :total-visible="15"
              ></v-pagination>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="isShowEditAction()">
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="editCase(item)"
                >mdi-application-edit-outline</v-icon
              >
            </template>
            <span>{{ editTitle(item) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="viewCase(item)"
                >mdi-home-search-outline</v-icon
              >
            </template>
            <span>檢視</span>
          </v-tooltip>
        </template>
        <!-- customize columns -->
        <!-- cover -->
        <template v-slot:item.cover="{ item }">
          <v-img
            width="100px"
            height="100px"
            contain
            :src="
              getCover(item).length > 0
                ? getCover(item)
                : require('@/assets/img/default.png')
            "
          ></v-img>
        </template>
        <template v-slot:item.inquire_count="{ item }">
          {{ item.inquires.length }}
        </template>
        <!-- <template v-slot:item.transaction_count="{ item }">
          {{ item.transactions.length }}
        </template> -->
        <template v-slot:item.total_area="{ item }">
          {{ displayArea(item) }}
        </template>
        <template v-slot:item.create_time="{ item }">
          {{ item.create_time | dateOnly }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type | getText(object_type_enum_str) }}
        </template>
        <template v-slot:item.deal_status="{ item }">
          {{ item.deal_status | getText(deal_status_str) }}
        </template>
        <template v-slot:item.shelf_status="{ item }">
          {{ item.shelf_status | getText(shelf_status_str) }}
        </template>
        <template v-slot:item.review_status="{ item }">
          {{ item.review_status | getText(review_status_enum_str) }}
        </template>
        <template v-slot:item.address="{ item }">
          <v-icon
            v-if="checkMarker(item)"
            large
            :color="getMarkColor(item)"
            @click.native.stop="openMap(item)"
            >mdi-map-marker</v-icon
          >{{ getAddress(item) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <v-row>
        <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
          <v-pagination
            prev-icon="fa fa-angle-left"
            next-icon="fa fa-angle-right"
            class="pagination"
            color="#cb0c9f"
            v-model="page"
            :length="getPageCount()"
            circle
            :total-visible="15"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  city_info,
  district_info,
  getCityName,
  getDistrictName,
} from "@/cdr.js";
import {
  transaction_type_enum,
  transaction_type_enum_str,
  edit_mode_enum,
} from "@/definitions.js";
import {
  building_style_enum_str,
  building_purpose_enum_str,
  object_type_enum,
  object_type_enum_str,
  defaultCase,
  land_ownership_enum_str,
  shelf_status_filter,
  sale_shelf_status_filter,
  deal_status_filter,
  case_mode_enum,
  deal_status_str,
  shelf_status_str,
  deal_status_enum,
  case_headers,
  draft_case_headers,
  manage_case_headers,
  shelf_status_enum,
  review_status_enum,
  review_status_enum_str,
  orientation_enum_str,
  sale_or_rent_enum_str,
  road_condition_enum_str,
  land_category_enum_str,
} from "@/case.js";
import { mapGetters } from "vuex";
import CaseMixin from "@/components/CRM/CaseMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CDRMixin from "@/components/CRM/CDRMixin.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { version } from "../../../../package.json";
import _ from "lodash";

export default {
  name: "case-base",
  props: {
    searchInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    propTitle: {
      type: String,
      default: "案件銷售",
    },
    propAllowSearch: {
      type: Boolean,
      default: true,
    },
    propCaseMode: {
      type: Number,
      default: case_mode_enum.List,
    },
  },
  mixins: [CaseMixin, CDRMixin],
  data: (vm) => ({
    // title: vm.$t("Case Sales"),
    object_type_enum: object_type_enum,
    object_type_enum_str: object_type_enum_str,
    object_type_enum: object_type_enum,
    transaction_type_enum: transaction_type_enum,
    transaction_type_enum_str: transaction_type_enum_str,
    building_style_enum_str: building_style_enum_str,
    building_purpose_enum_str: building_purpose_enum_str,
    land_ownership_enum_str: land_ownership_enum_str,
    deal_status_str: deal_status_str,
    deal_status_enum: deal_status_enum,
    deal_status_filter: deal_status_filter,
    shelf_status_str: shelf_status_str,
    city_info: city_info,
    district_info: district_info,
    edit_mode_enum: edit_mode_enum,
    case_mode_enum: case_mode_enum,
    case_headers: case_headers,
    draft_case_headers: draft_case_headers,
    manage_case_headers: manage_case_headers,
    shelf_status_enum: shelf_status_enum,
    review_status_enum: review_status_enum,
    review_status_enum_str: review_status_enum_str,
    orientation_enum_str: orientation_enum_str,
    sale_or_rent_enum_str: sale_or_rent_enum_str,
    road_condition_enum_str: road_condition_enum_str,
    land_category_enum_str: land_category_enum_str,
    edit_dialog: false,
    start_menu: false,
    start_date: null,
    end_menu: false,
    end_date: null,
    keyword: "",
    case_display: false,
    case_edit_mode: edit_mode_enum.View,
    case_edited_item: {},
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    defaultItem: defaultCase,
    originItem: {},
    // options: {},
    object_type_filter: [
      { text: "土地", value: "L" },
      { text: "建物", value: "B" },
      { text: "全部", value: null },
    ],
    shelf_status_filter: shelf_status_filter,
    sale_shelf_status_filter: sale_shelf_status_filter,
    search: {
      serial_number: null,
      type: null,
      name: null,
      address: null,
      lbkey: null,
      styles: [],
      purposes: [],
      exteriors: [],
      rooms: null,
      has_parking: null,
      floor_materials: [],
      land_ownerships: [],
      land_category: [],
      city: null,
      district: null,
      region: null,
      road: null,
      mno: null,
      sno: null,
      shelf: null,
      deal: null,
      review: null,
      total_min: null,
      total_max: null,
      area_min: null,
      area_max: null,
      object_name: null,
      sale_id: null,
      room_min: null,
      room_max: null,
      orientation: null,
      sale_or_rent: null,
      road_conditions: null,
    },
    version: version,
    selected: [],
    loading: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      show_confirm: false,
    },
    sales_filter: [],
    common_input_class: "font-size-input border border-radius-md",
  }),
  components: {
    CommonLoading,
    CaseDetail: () => import("./CaseDetailDialog.vue"),
  },
  beforeMount() {
    if (this.isAllowFilterCreator()) {
      this.getUserOfSales();
    }
  },
  mounted() {
    console.log("app version", this.version);
    if (this.propCaseMode == case_mode_enum.List) {
      this.search.shelf = shelf_status_enum.ON_SHELF;
    }
  },
  watch: {
    page() {
      this.getAllCases();
    },
    options: {
      handler() {
        this.getAllCases();
      },
    },
  },
  methods: {
    ...mapGetters([
      "getToken",
      "isAllowAddCase",
      "isAllowEditCase",
      "isAllowExportExcelCase",
      "isAllowFilterDeal",
      "isAllowFilterShelf",
      "isAllowFilterCreator",
    ]),
    isShowEditAction() {
      // console.log(" Read only.", !this.readOnly, this.isAllowEditCase());
      return (
        !this.readOnly &&
        (this.isAllowEditCase() || this.propCaseMode == case_mode_enum.Draft)
      );
    },
    getAllCases() {
      if (this.propCaseMode == case_mode_enum.Draft) {
        this.search.draft = true;
      } else if (this.propCaseMode == case_mode_enum.List) {
        // this.search.shelf = shelf_status_enum.ON_SHELF;
        console.log("list mode", this.search.shelf);
      }
      if (this.propCaseMode == case_mode_enum.Draft) {
        this.getMyCases();
      } else {
        this.getCases();
      }
    },
    getPageCount() {
      return Math.ceil(this.case_items_count / this.itemsPerPage);
    },
    reset() {
      console.log("reset");
      this.page = 1;
      this.start_date = null;
      this.end_date = null;
      this.case_items = [];
      this.case_items_count = 0;
      this.search = {
        serial_number: null,
        type: null,
        name: null,
        address: null,
        lbkey: null,
        styles: [],
        purposes: [],
        exteriors: [],
        room_min: null,
        room_max: null,
        has_parking: null,
        floor_materials: [],
        land_ownerships: [],
        land_category: [],
        city: null,
        district: null,
        region: null,
        road: null,
        mno: null,
        sno: null,
        shelf: null,
        deal: null,
        review: null,
        total_min: null,
        total_max: null,
        area_min: null,
        area_max: null,
        object_name: null,
        sale_id: null,
        draft: false,
        orientation: null,
        sale_or_rent: null,
        road_conditions: null,
      };
      if (this.propCaseMode == case_mode_enum.List) {
        this.search.shelf = shelf_status_enum.ON_SHELF;
      }
      this.districts = [];
      this.regions = [];
      this.onSearch();
    },
    onSearch() {
      if (this.search.type == object_type_enum.Land) {
        this.search.lbkey = CommonUtility.getLandKey(this.search);
      }
      console.log("search", this.search);
      this.page = 1;
      this.onOptionChange();
    },

    onOptionChange() {
      this.page = 1;
      this.getAllCases();
    },
    addCase() {
      console.log("create case");
      this.case_edit_mode = edit_mode_enum.Create;
      this.case_edited_item = _.cloneDeep(this.defaultItem);
      this.case_display = true;
    },
    viewCase(item) {
      console.log("view item id:", item.id, " item:", item);
      this.case_edit_mode = edit_mode_enum.View;
      this.case_edited_item = _.cloneDeep(item);
      this.case_display = true;
    },
    editTitle(item) {
      switch (item.review_status) {
        case review_status_enum.Draft:
          return "檢視";
        case review_status_enum.Submitting:
          return "審核";
        default:
          return "編輯";
      }
    },
    editCase(item) {
      this.case_edit_mode = edit_mode_enum.Edit;
      this.case_edited_item = _.cloneDeep(item);
      this.originItem = _.cloneDeep(item);
      this.case_display = true;
    },
    onClear() {
      this.originItem = _.cloneDeep(this.defaultItem);
      this.case_edited_item = _.cloneDeep(this.defaultItem);
    },
    handleClick(item) {
      console.log("handle click", item);
    },
    close() {
      this.onClear();
      this.case_edit_mode = edit_mode_enum.View;
      this.case_display = false;
    },
    onActionDone(msg) {
      this.close();
      this.getAllCases();
      this.showMessage(msg);
    },
    onReload(case_id) {
      console.log("onReload:", case_id);
      // TODO 這邊要優化 只更新單一筆資料 case_id
      this.getAllCases();
    },
    showMessage(message) {
      this.loading = {
        dialog: true,
        message: message,
        show_loading: false,
        show_confirm: true,
      };
    },
    getObjectInfo() {
      let objects = [];
      this.case_edited_item.objects.forEach((item) => {
        if (item.location != null) {
          console.log("get", item.district);
          objects.push({
            type: item.type,
            location: item.location,
            city: item.city != null ? item.city : null,
            district: item.district,
            id: item.id,
          });
        }
      });
      return objects;
    },
    displayArea(item) {
      return CommonUtility.getTotalArea(item);
    },
    getCover(item) {
      if (item.photos.length > 0) {
        // find the type == 0  and return the url
        for (var i = 0; i < item.photos.length; i++) {
          if (item.photos[i].type == 0) {
            const photo = item.photos[i].key;
            // console.log(item.photos[i]);
            const url = item.photo_base + photo;
            return url;
          }
        }
      }
      return "";
    },
    getAddress(item) {
      let address = "";
      if (item.type == object_type_enum.Land) {
        if (item.lands.length > 0) {
          const land = item.lands[0];
          const count = item.lands.length;
          if (CommonUtility.validateKey(land.lbkey)) {
            // A_03_0003_0002-0000
            const keys = land.lbkey.split("_");
            const city_code = keys[0];
            const district_code = keys[1];
            const nos = keys[3];
            const city = getCityName(city_code);
            const district = getDistrictName(city_code, district_code);
            if (city && district) {
              address = city.text + district.text;
              if (land.region_name != null) {
                address += land.region_name;
                // 去0
                let new_nos = CommonUtility.formatString(nos);
                address = `${address}${new_nos}`;
                if (item.lands.length > 1) {
                  address = `${address}等${item.lands.length}筆`;
                }
                address = `${address}地號`;
              }
            }
          }
        }
      } else if (item.type == object_type_enum.Building) {
        if (item.buildings.length > 0) {
          // console.log("ok building", item.buildings[0]);
          const building = item.buildings[0];
          const city = getCityName(building.city);
          const district = getDistrictName(building.city, building.district);
          if (city && district) {
            address = city.text + district.text;
            if (building.road_name) {
              address += building.road_name;
            }
          }
          if (building.address) {
            address += building.address;
          }
        }
      }
      return address;
    },
    onSelectType(type) {
      this.search.city = null;
      this.search.district = null;
      this.search.region = null;
    },
    checkMarker(item) {
      if (item.type == "L" && item.lands.length > 0) {
        return this.isAllowLocation(item.lands[0]);
      } else if (item.type == "B" && item.buildings.length > 0) {
        return this.isAllowLocation(item.buildings[0]);
      }

      return false;
    },
    getMarkColor(item) {
      if (item.type == "L" && item.lands.length > 0) {
        return this.getColor(item.lands[0]);
      } else if (item.type == "B" && item.buildings.length > 0) {
        return this.getColor(item.buildings[0]);
      }
      return "grey";
    },

    openMap(info) {
      console.log("open map");
      let item = null;
      if (info.type == "L" && info.lands.length > 0) {
        item = info.lands[0];
      } else if (info.type == "B" && info.buildings.length > 0) {
        item = info.buildings[0];
      }

      if (item == null || item.latitude == null || item.longitude == null) {
        return;
      }
      // <a href="https://www.google.com/maps?q=25.034,121.545" target="_blank">Open Google Maps</a>
      const linkElement = document.createElement("a");
      // 设置 href 属性为 Google 地图链接
      linkElement.href = `https://www.google.com/maps?q=${item.latitude},${item.longitude}`;
      // 设置 target 属性为 "_blank"，在新的标签页中打开链接
      linkElement.target = "_blank";
      // 设置 <a> 元素的文本内容
      linkElement.textContent = "Open Google Maps";
      window.open(linkElement.href, "_blank");
    },
    exportExcel() {
      console.log("export excel", this.selected);
      // get the id from the selected
      const ids = [];
      this.selected.forEach((item) => {
        ids.push(item.id);
      });
      console.log("export the excel by ids", ids);

      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/excel`;
      const data = {
        ids: ids,
      };
      this.doHttpPost(
        url,
        data,
        this.onExportExcelSuccess,
        this.onExportExcelFail,
        this.onExportFinal
      );
    },
    onExportExcelSuccess(data) {
      console.log("start to download", data);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/download`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
        responseType: "blob", // important
        params: { filename: data.filename, path: data.path },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.forceFileDownload(response, data.filename);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onExportExcelFail(error) {
      console.log("error ", error);
      this.showMessage("匯出失敗");
    },
    onExportFinal() {
      this.selected = [];
    },
    selectDistrict(city, district) {
      if (this.search.type == "L") {
        this.onSelectDistrict(city, district, true);
      } else {
        this.onSelectDistrict(city, district, false);
      }
    },
    getUserOfSales() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/sales`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      this.loading = true;
      this.doHttpGet(
        url,
        params,
        this.setSalesResult,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    setSalesResult(data) {
      // console.log(data);
      this.sales_filter = data;
    },
  },
  computed: {
    searchAddr() {
      if (this.search.type == null) {
        return "地址/地號";
      }
      if (this.search.type == object_type_enum.Land) {
        return "地號";
      }
      return "地址";
    },
    addrWidth() {
      if (this.search.type == object_type_enum.Building) {
        return 3;
      }
      return 5;
    },
    headers() {
      switch (this.propCaseMode) {
        case case_mode_enum.Draft:
          return this.draft_case_headers;
        case case_mode_enum.Manage:
          return this.manage_case_headers;
        default:
          return this.case_headers;
      }
      // if (this.propCaseMode == case_mode_enum.Draft) {
      //   return this.draft_case_headers;
      // }
      // return this.case_headers;
    },
    readOnly() {
      return this.propCaseMode == case_mode_enum.List;
    },
    shelf_filter_options() {
      if (this.propCaseMode == case_mode_enum.List) {
        return this.sale_shelf_status_filter;
      }
      return this.shelf_status_filter;
    },
  },
};
</script>

<style>
.v-data-table-header-mobile {
  display: none !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 1px;
}
</style>
