<template>
  <CaseList :propCaseMode="case_mode"></CaseList>
</template>
<script>
import { case_mode_enum } from "@/case.js";
import CaseList from "./CaseList.vue";
export default {
  name: "case-sale",
  components: {
    CaseList,
  },
  data: (vm) => ({
    title: vm.$t("Case Management"),
    case_mode_enum: case_mode_enum,
    case_mode: case_mode_enum.List,
  }),
};
</script>
